import React from "react"
import financialgif from "../../assets/images/banners/industry-images/pexels-essow-936722.jpg"
import financialbanner from "../../assets/images/banners/new-banners/financial.jpg"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
// import "../../assets/js/vendor/bootstrapp.min.js"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const Financial = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img financial"
          data-bg={financialbanner}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Financial Sector</h1>
                  <h5 className="font-weight--normal text-white">
                    Financial services companies constantly deal with issues
                    such as improving service quality in today's fast-paced
                    environment marked by technological evolution.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--==================== Header Area Start End  ====================-->
            <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">
                    Our Industry Solution
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Financial <span className="text-color-primary">Sector</span></h2>
                  In today’s fast-paced world, which is characterized by
                  technological evolution, financial services companies
                  continuously deal with challenges such as improving
                  quality of service, quick claims management, reducing
                  time-to-market, improving functionality, technology
                  obsolescence, and pressure to control IT costs. These
                  businesses are increasingly striving to grow their
                  operations in core and emerging regions, develop
                  innovative products and services for shifting customer
                  demographics, and meet severe regulatory requirements
                  while reducing costs. Our clients have benefited from
                  Aamanto IT services, solutions, accelerators, and
                  flexible engagement models. We have the experience,
                  expertise, and capabilities at Aamanto to help you
                  innovate and reinvent your business while also
                  reducing your IT budget.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={financialgif} alt="financial" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============--> */}
      </div>
    </div>
  )
}

export default Financial
