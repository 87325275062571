import * as React from "react"
import Layout from "../components/layout"
import Financial from "../components/Industries/Financial"






const financialsector = () => {
  return (
  <Layout>
   <Financial/>
  </Layout>
)
};
export default financialsector;
